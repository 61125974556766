import * as Styled from './locationDataTableStyles';
import React from 'react';
import DataTable from 'react-data-table-component';
import { graphql, useStaticQuery } from 'gatsby';
import TableHeaders from '../../constants/tableHeaders';
import Blueline from '../blueLine/blueLine';
import InDevelopment from '../../images/InDevelopment.png';
import operational from '../../images/Operational.png';
import underConstruction from '../../images/UnderConstruction.png';
import RedPin from '../../images/blue_pin.png';
import GrayPin from '../../images/gray_pin.png';
import GreenPin from '../../images/green_pin.png';
import LocationCorousel from './locationCorousel';


const createHeaders=(data)=>{
    
    //Extracting Keys/Columns
    let keys = Object.keys(data[0]);

    const headers = [];

    for (var i=1; i < keys.length; i++) {
        var sample = {};
        sample.name=TableHeaders[keys[i]];
        sample.selector=keys[i];
        sample.sortable = true;
        if(keys[i]==='sitename'){
        sample.width ='25%';
        }
        if(keys[i]!=='commissioningOrAcquisition' && keys[i]!=='locationImage' && keys[i]!=='locationPdf' && keys[i]!=='status'){
            headers.push(sample);
        }
        
    } 

    return headers;
};


const Table = ({sectionData}) => {

let aggregateOperational = 0;
let aggregateInDevelopment = 0;
let aggregateUnderConstruction = 0;

const featuredSitesData = useStaticQuery(graphql`
    query allFeaturedSiteQuery {
        allContentfulSolLocations(filter: {node_locale: {eq: "en-US"}}) {
            nodes {
            id
            energy {
                title
                url
            }
            segment
            sitename
            status
            commissioningOrAcquisition
            grossCapacityKWp
            country
            locationImage {
                raw
            }
            locationPdf {
            raw
            }
            }
        }
        }
    `);

const createData =(data)=>{
    
    const columnData = [];
    for (var i=0; i < data.length; i++) {
        var obj = {};
        obj.energy=<Styled.Icon src={data[i]['energy']['url']} alt='img'/>;
        obj.segment = data[i]['segment'];
        obj.sitename = data[i]['sitename'];
        // obj.status = data[i]['status'];
        //obj.commissioningOrAcquisition = data[i]['commissioningOrAcquisition'];
        obj.grossCapacityKWp = data[i]['grossCapacityKWp'];
        obj.country = data[i]['country']
        columnData.push(obj);
        if(data[i]['status']=== 'Operational'){
            aggregateOperational+=1;
        }
        if(data[i]['status']=== 'In Development'){
            aggregateInDevelopment+=1;
        }
        if(data[i]['status']=== 'Under Construction'){
            aggregateUnderConstruction+=1;
        }

    } 
    return columnData;

}
        
    // customStyles for table.
    const customStyles = {
        table: {
            style: {
                maxHeight:'560px',
                overflow:'scroll',
            },
        },
        rows: {
            
        },
        headCells: {
            style: {
                backgroundColor: '#00AE4F',
                color: '#fff',
                fontSize: '18px',
                fontWeight:'bold',
                textAlign: 'center',
                padding: '15px',
                border: '2px solid',
                justifyContent:'center',
                
            },
        },
        cells: {
            style: {
                color:'#007DC3',
                textAlign:'center',
                fontSize: '16px',
                fontWeight: 'bold',
                padding: '5px',
                border: '1px solid #e7e7e7',
                justifyContent:'center',
            },
        },
    };
    
    //headers
    const headers = createHeaders(featuredSitesData?.allContentfulSolLocations?.nodes);
    
    const data = createData(featuredSitesData?.allContentfulSolLocations?.nodes);
    
    let [filteredData,setFilteredData]=React.useState(data);
    // filter data
    const handleChange = (event) => {
        const value = event.target.value.toLowerCase();
        filteredData = setFilteredData( data.filter((item=>
        item['sitename'].toLowerCase().includes(value) || 
        item['country'].toLowerCase().includes(value) ||
        item['grossCapacityKWp'].toString().includes(value) || 
        item['segment'].toLowerCase().includes(value))));
        
        };

    // Check Featured Sites
    const featuredSites = featuredSitesData?.allContentfulSolLocations?.nodes?.filter(site=>site.locationImage !== null && site.locationPdf !== null);
    
return (
    
    <Styled.MainDiv>
        <Styled.AggregateDataContainer>
            {/* <Styled.CardTextStyle>
                <Styled.IconAggregateData src={InDevelopment} />
                <Styled.header3> { aggregateInDevelopment} <Styled.header6>Sites</Styled.header6></Styled.header3>
                <div>
                <Styled.PinIcon src={GrayPin} alt='icon'></Styled.PinIcon>
                In Development
                </div>
            </Styled.CardTextStyle>
            <Styled.CardTextStyle>
                <Styled.IconAggregateData src={underConstruction} />
                <Styled.header3> {aggregateUnderConstruction} <Styled.header6>Sites</Styled.header6></Styled.header3> 
                <div>
                <Styled.PinIcon src={RedPin} alt='icon'></Styled.PinIcon>      
                Under Construction
                </div>
            </Styled.CardTextStyle> */}
            <Styled.CardTextStyle >
                <Styled.IconAggregateData src={operational} />
                <Styled.header3> {aggregateOperational} <Styled.header6>Sites</Styled.header6></Styled.header3>
                <div>
                <Styled.PinIcon src={GreenPin} alt='icon'></Styled.PinIcon>
                Operational
                </div>
            </Styled.CardTextStyle>
        </Styled.AggregateDataContainer>
            <Blueline />
            
            <LocationCorousel featuredSites={featuredSites} />
            
            <Styled.TitleContainer>
                <Styled.LineBehind><Styled.LineBehindSpan>Project List</Styled.LineBehindSpan></Styled.LineBehind>
                
            </Styled.TitleContainer>
            <Styled.SearchBox type='text'
            placeholder='Search'
            onChange={handleChange}
            />
    <DataTable
    columns={headers}
    data={filteredData}
    customStyles={customStyles}
    defaultSortFieldId={6}
    fixedHeader
/>
</Styled.MainDiv>   
);
};

export default Table;
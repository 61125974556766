const TableHeaders = {
    energy:"Energy",
    segment:"Segment",
    sitename:"Site Name", 
    // status:"Status",
    commissioningOrAcquisition:"Commissioning or Acquisition",
    grossCapacityKWp:"Capacity (kWp)",
    country:"Country",
};

export default TableHeaders;